import React from 'react';
import '../App.css';
import AccountTable from "../components/economy/AccountTable";
import Utils from '../utils/Utils';
import TransactionTable from "../components/economy/TransactionTable";
import DownloadUtil from "../utils/DownloadUtil";

class AccountView extends React.Component {
    constructor(props) {
        super(props);
        // this.years = [ 2020, 2021, 2022, 2023, 2024 ];
        this.years = [ 2020, 2021, 2022, 2023, 2024 ];
        this.state = {excelContent: null, assets: {}, expenses: {}, income: {}, liabilities: {}, isLoading: 4, error: null, transactions: {}, verification: {}, account: {}, yearDetails: 0};
   }

   componentDidMount() {

       const header = Utils.getAuthorizationHeaders();

       let targetUrl = Utils.createUrl('/brfa/api/accounting/balance/'+this.years+'/ASSETS');
       Utils.fetch(targetUrl, header, (data) => {
            this.setState({assets: data, isLoading: this.state.isLoading - 1});
       }, (error) => {
           if (error == 401) {
           }
       });

       targetUrl = targetUrl = Utils.createUrl('/brfa/api/accounting/balance/'+this.years+'/EXPENSES');
       Utils.fetch(targetUrl, header, (data) => {
           this.setState({expenses: data, isLoading: this.state.isLoading - 1});
       }, (error) => {
           if (error == 401) {
           }
       });

       targetUrl = Utils.createUrl('/brfa/api/accounting/balance/'+this.years+'/INCOME');
       Utils.fetch(targetUrl, header, (data) => {
           this.setState({income: data, isLoading: this.state.isLoading - 1});
       }, (error) => {
           if (error == 401) {
           }
       });

       targetUrl = Utils.createUrl('/brfa/api/accounting/balance/'+this.years+'/LIABILITIES');
       Utils.fetch(targetUrl, header, (data) => {
           this.setState({liabilities: data, isLoading: this.state.isLoading - 1});
       }, (error) => {
           if (error == 401) {
           }
       });
    }

    render() {

        let headingStyle = {
            float: 'left',
            width: '50%',
            padding: '10px',
            height: '24pt',
            fontWeight: 'bold',
            // fontSize: '18pt',
            color: '#949494'};

       if (this.state.error) {
           // this.reject(this.state.error);
           this.setState({isLoading: 0, error: null})
           return <div>Error!</div>
       }

        if (this.state.isLoading != 0) {
           return <div>{this.state.isLoading}</div>;
       }

        if (this.state.excelContent != null) {
            DownloadUtil.getFile(this.state.excelContent);
            this.state.excelContent = null;
        }

        if (0==0) {
            return <div style={{height: 'calc(100vh - 71px)', background: '#EEEEEE'}}>
               <div style={{height: 'calc(50% - 5px)', background: 'white', overflow: 'auto', margin: '5px', border: '1px dotted gray'}}>
                   <div style={{float: 'left', width: '100%'}}>
                       <div style={headingStyle}>{this.state.income.label}</div>
                       <div style={{width: '30%', float: 'right', padding: '10px'}}>
                           <button onClick={() => this.onExport(this.state.income.years, 'INCOME')}style={{paddingLeft: '10px', marginTop: '6px', float: 'right'}}>
                               Exportera
                           </button>
                           <button onClick={() => this.onExport(this.state.income.years, 'ALL')}style={{marginRight: '10px', marginTop: '6px', float: 'right'}}>
                               Exportera allt
                           </button>
                       </div>
                       <AccountTable onClick={(e, i) => this.accountClick(e, i)} heading={this.state.income.label} years={this.state.income.years} balances={this.state.income.balances}/>
                   </div>
                   <div style={{float: 'left', width: '100%'}}>
                       <div style={headingStyle}>{this.state.expenses.label}</div>
                       <div style={{width: '30%', float: 'right', padding: '10px'}}>
                           <button onClick={() => this.onExport(this.state.income.years, 'EXPENSES')}style={{marginTop: '6px', float: 'right'}}>
                               Exportera
                           </button>
                       </div>
                       <AccountTable onClick={(e, i) => this.accountClick(e, i)} heading={this.state.expenses.label} years={this.state.expenses.years} balances={this.state.expenses.balances}/>
                   </div>
                   <div style={{float: 'left', width: '100%'}}>
                       <div style={headingStyle}>{this.state.assets.label}</div>
                       <div style={{width: '30%', float: 'right', padding: '10px'}}>
                           <button onClick={() => this.onExport(this.state.income.years, 'ASSETS')}style={{marginTop: '6px', float: 'right'}}>
                               Exportera
                           </button>
                       </div>
                       <AccountTable onClick={(e, i) => this.accountClick(e, i)} heading={this.state.assets.label} years={this.state.assets.years} balances={this.state.assets.balances}/>
                    </div>
                   <div style={{float: 'left', width: '100%'}}>
                       <div style={headingStyle}>{this.state.liabilities.label}</div>
                       <div style={{width: '30%', float: 'right', padding: '10px'}}>
                           <button onClick={() => this.onExport(this.state.income.years, 'LIABILITIES')}style={{marginTop: '6px', float: 'right'}}>
                               Exportera
                           </button>
                       </div>
                       <AccountTable onClick={(e, i) => this.accountClick(e, i)} heading={this.state.liabilities.label} years={this.state.liabilities.years} balances={this.state.liabilities.balances}/>
                    </div>
                </div>
               <div style={{height: 'calc(50% - 5px)', overflow: 'auto', margin: '5px', border: '1px dotted gray'}}>
                   <div style={{height: 'fit-content', cursor: 'pointer'}}>
                       <TransactionTable onRefresh={() => this.doRender()} onClick={(e) => this.verificationClick(e)} year = {this.state.yearDetails} verification = {this.state.verification} transactions = {this.state.transactions} account = {this.state.account}/>
                   </div>
               </div>
           </div>
       }
   }

   onExport(years, accountClass) {
       this.state.isLoading = true;
       document.body.classList.add('busy-cursor');
       let targetUrl = Utils.createUrl('/brfa/api/file/download/excel/' + years + '/'+ accountClass);
       const headers = Utils.getAuthorizationHeaders();
       fetch(targetUrl, headers)
           .then(data => {
               this.setState({ excelContent: data, isLoading: false, document: true} );
           });

   }

   accountClick(balance, col) {
        if (col >= this.years.length) { return; }

        if (balance == null) {
            this.setState({transactions: {}});
            return;
        }

        const targetUrl = Utils.createUrl('/brfa/api/accounting/transaction/' + this.years[col] + '/account/' + balance.account);

        fetch(targetUrl, Utils.getAuthorizationHeaders())
           .then(response => {
               return response.json();
           }).then(data => {
           if (data) {
               this.setState({transactions: data, account: balance, isLoading: 0, yearDetails: this.years[col]});
           }
       }).catch(error => {
           alert("!!!");
           this.setState({error: error, isLoading: 0});
       })
   }

    verificationClick(verification) {
        if (verification == null) {
            return;
        }



//         history.push("/accounting/" + verification);

        window.location.replace('/accounting/' + (verification == 0 ? '' : verification));

        /*
                const targetUrl = Utils.createUrl('/brfa/api/accounting/verification/' +verification);

                fetch(targetUrl, Utils.getAuthorizationHeaders())
                    .then(response => {
                        return response.json();
                    }).then(data => {
                    if (data) {
                        this.setState({verification: data});
                    }
                });

         */
    }

    transactionSearch(searchString) {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/search/' + searchString);

        fetch(targetUrl, Utils.getAuthorizationHeaders())
            .then(response => {
                return response.json();
            }).then(data => {
            if (data) {
                this.setState({transactions: data, isLoading: 0});
            }
        });
    }

    doRender() {
        this.state.isLoading = 4;
        this.componentDidMount();
    }
}

export default AccountView;