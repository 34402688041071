import Conf, {BASE_URL} from "../core/conf";

let menuComponent;
class Utils {

    static versionInfo = {};

    static getLoginInfo() {
        return JSON.parse(localStorage.getItem('loginInfo'));
    }

    static getAuthorizationHeaders(contentType, force= false) {

        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
        if (loginInfo == null) {
            if (force == true) {
                window.location = '/login';
            }
            else {
                return {
                    headers: {
                        'Content-Type': contentType == null ? 'application/json' : contentType
                    }
                }
            }
        }

        const bearerToken = 'Bearer ' + loginInfo.bearerToken;

        return {
            headers: {
                'Authorization': bearerToken,
                'Content-Type': contentType == null ? 'application/json' : contentType
            }
        }
    }

    static getUserInfo() {
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
        if (loginInfo) {
            return loginInfo.user;
        }
    }

    static setVersionInfo(data) {
        this.versionInfo = data;
    }

    static getCommitTime() {
        if (this.versionInfo != null) {
            let d = new Date(0);
            return d.setUTCSeconds(this.versionInfo.commitTime).toLocaleString('sv-SE', { timeZone: 'UTC' });
        }
    }

    static createUrl(location) {
        return BASE_URL + location;
    }

    static createUrlWithProtocol(protocol) {
        let bp = BASE_URL.indexOf("//");
        return protocol + BASE_URL.substring(BASE_URL.indexOf(bp+2));
    }

    static numberAsCurrency(number) {
        if ('number' !== typeof number) {
            return '';
        }

        var parts = number.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(",")+" Kr";
    }

    static getBoard(callback) {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/board');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            callback(data);
        });
    }

    static fetch(targetUrl, headers, onSuccess, onError) {
        fetch(targetUrl, headers)
            .then(response => {

                if (response.status !== 200) {
                    const status = response.status;
                    return Promise.reject(status)
                }

                return response.json();
            }).then(data => {
            onSuccess(data);
        }).catch(error => {
            onError(error);

            switch (error) {

                case 401: alert("Du har blivit utloggad");
                    localStorage.clear("loginInfo");
                    break;

                case 403: alert("Du har engett ett felaktigt användarnamn eller lösenord, var god försök igen!");
                    break;
            }

        });
    }

    static disableMenu(disabled) {
        sessionStorage.setItem("menuDisabled", disabled.toString());
        menuComponent.refresh();
    }
    static isMenuDisabled() {
        let disabled  = sessionStorage.getItem("menuDisabled")
        // alert(disabled);
        return disabled == "true";
    }

    static setMenuComponent(ref) {
        menuComponent = ref;
    }
}

export default Utils;

