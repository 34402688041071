import React from 'react';
import Utils from "../utils/Utils";

class Intercom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {exportedContent: [], document: false, isLoading: true};
    }

    componentDidMount() {
        let targetUrl = Utils.createUrl('/brfa/api/admin/generate/intercom');
        const headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {

            // let imageUrls = this.state.imageUrls;
            let imageUrls = []
            let fileName = data.fileName;
            for (let i=0;i<data.imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[i];
                imageUrls[i] = url;
            }
            let url = "data:image/pdf;base64,"+data.imageData.pdfDocument;
            this.setState({ imageUrls: imageUrls, pdfDocument: url, pdfFileName: fileName, isLoading: false})
        });
    }

    render() {
        let now = new Date();

        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return(
            <div  style = {{float: 'left', margin: '12pt', boxShadow: 'rgba(0, 0, 0, 0.9) 0px 1px 12px'}}>
                <img onClick={(event) => this.onThumbClick(event, this.state.src)}
                 src={this.state.imageUrls[0]}
                 height='1024'
                 style={{
                     width: this.state.thumbsSize + 'px',
                     margin: '0px',
                     padding: '0px'
                 }}/>
        </div>)

    }

    onThumbClick() {

    }

    handleClose() {
        this.close();
    }
}

export default Intercom;
